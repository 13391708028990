import { useState, useEffect, useCallback } from 'react';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import fbxFile from '../../fbx/c-insignia--small1.fbx';


let camera, scene, renderer;

let logoMaterial;

window.rotation = 0;

let circle, c;
let logo;

let dataUrl;

const MenuButton = (props) => {
  const [isInitialised, setIsInitialised] = useState(false);

  let canvasWidth = 80,
      canvasHeight = 80;

  const rgbToHsl = (red, green, blue) => {
    let r = red / 255;
    let g = green / 255;
    let b = blue / 255;

    const max = Math.max(r, g, b), min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
        default: break;
      }

      h /= 6;
    }

    return {
      h: h,
      s: s,
      l: l
    };
  }

  /**
   * Converts an HSL color value to RGB. Conversion formula
   * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
   * Assumes h, s, and l are contained in the set [0, 1] and
   * returns r, g, and b in the set [0, 255].
   *
   * @param   Number  h       The hue
   * @param   Number  s       The saturation
   * @param   Number  l       The lightness
   * @return  Array           The RGB representation
   */
  const hslToRgb = (h, s, l) => {
    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1/6) return p + (q - p) * 6 * t;
        if (t < 1/2) return q;
        if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
        return p;
      }

      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;

      r = hue2rgb(p, q, h + 1/3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1/3);
    }

    return {
      r: r * 255,
      g: g * 255,
      b: b * 255
    };
  }

  const createBackgroundTexture = () => {
    const imageCanvas = document.createElement('canvas');
    imageCanvas.setAttribute('id', 'imageCanvas');
    document.body.appendChild(imageCanvas);

    imageCanvas.width = 1024;
    imageCanvas.height = 1024;
    const ctx = imageCanvas.getContext('2d');
    var grd = ctx.createRadialGradient(75, 50, 5, 90, 60, 1024);
    grd.addColorStop(0, "#fff");
    grd.addColorStop(0.6, "#ccc");
    grd.addColorStop(1, "#fff");
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, 1024, 1024);

    dataUrl = imageCanvas.toDataURL();
  }

  const init = useCallback((color, rainbow) => {

    const container = document.querySelector('#menuButton');

    camera = new THREE.PerspectiveCamera(45, canvasWidth / canvasHeight, 1, 2000);
    camera.position.set(0, 0, 1500);

    scene = new THREE.Scene();

    const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444);
    hemiLight.position.set(0, 20, 0);
    hemiLight.intensity = 0.4;
    scene.add(hemiLight);

    const dirLight = new THREE.DirectionalLight(0xffffff, 1);
    dirLight.position.set(0, 200, 100);
    dirLight.castShadow = true;
    dirLight.shadow.camera.top = 180;
    dirLight.shadow.camera.bottom = - 100;
    dirLight.shadow.camera.left = - 120;
    dirLight.shadow.camera.right = 120;
    scene.add(dirLight);

    const ambientLight = new THREE.AmbientLight(0xFFFFFF, 0.6);
    scene.add(ambientLight);

    createBackgroundTexture();

    const reflections = new THREE.CubeTextureLoader().load([dataUrl, dataUrl, dataUrl, dataUrl, dataUrl, dataUrl]);

    logoMaterial = new THREE.MeshPhongMaterial({
      color: 0xffffff,
      envMap: reflections,
      transparent: true,
      clipShadows: true,
      opacity: 1,
      shininess: 99,
      refractionRatio: 0.9,
      reflectivity: 0.99,
      flatShading: true
    });

    logoMaterial.color = color;

    // model
    const loader = new FBXLoader();
    loader.load(fbxFile, function (object) {

      logo = object;

      object.position.y = 0;
      object.rotation.x = Math.PI / 2;
      logo.scale.set(16, 16, 16)

      logo.traverse(function (child) {
        if (child.isMesh) {
          if (child.name !== 'circle') {
            child.castShadow = true;
            child.receiveShadow = true;
            child.material = logoMaterial;
            c = child;
          } else {
            child.material = logoMaterial;
            circle = child;
          }
        } else {
          if (child.name === 'Light') {
            if (child.color) {
              child.color.r = 0;
              child.color.g = 0;
              child.color.b = 0;
            }
          }
        }
      });

      scene.add(logo);
    });

    const menuButtonCanvas = document.querySelector('#menuButtonCanvas');

    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true, canvas: menuButtonCanvas });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor(0x000000, 0);
    renderer.setSize(canvasWidth, canvasHeight);
    renderer.shadowMap.enabled = true;
    container.appendChild(renderer.domElement);

    const animate = () => {

      requestAnimationFrame(animate);

      if (c && circle) {
        logo.rotation.z = window.rotation;
        if (window.showInfo === true) {
          if (window.rotation > 0.1) {
            window.rotation -= 0.1;
          } else if (window.rotation < -0.1) {
            window.rotation += 0.1;
          } else {
            if (logoMaterial.opacity > 0) {
              logoMaterial.opacity -= 0.01;
              logoMaterial.needsUpdate = true;
            }
          }
        }
      }

      if (c) {
        if (c.rotation) {
          window.rotation += 0.02;
        }
      }

      if (rainbow === true) {
        if (logoMaterial) {
          const increment = 0.25;
          const currentColorHSL = rgbToHsl(logoMaterial.color.r, logoMaterial.color.g, logoMaterial.color.b);
          const currentHue = currentColorHSL.h * 360;
          const newHue = currentHue < 360 - increment ? currentHue + increment : 0;
          const newHueDecimal = newHue / 360;
          const newCurrentColor = hslToRgb(newHueDecimal, currentColorHSL.s, currentColorHSL.l);
          logoMaterial.color = newCurrentColor;
        }
      }

      renderer.autoClear = false;

      renderer.clear();
      renderer.render(scene, camera);
    }

    animate();

  }, [canvasHeight, canvasWidth]);

  useEffect(() => {
    if (props.homepageDataIsReceived === true && props.homepageData) {
      if (isInitialised === false) {
        let color, rainbow;

        if (props.homepageData.insignia_colour) {
          const rgb = props.homepageData.insignia_colour;
          const rgbArray = rgb.substring(4, rgb.length-1)
                .replace(/ /g, '')
            .split(',');

          // * by this number is the same as / by 255
          color = {
            r: parseInt(rgbArray[0]) * 0.00392156863,
            g: parseInt(rgbArray[1]) * 0.00392156863,
            b: parseInt(rgbArray[2]) * 0.00392156863
          }
        } else {
          color = {
            r: 1,
            g: 1,
            b: 1
          }
        }
        if (props.homepageData.insignia_is_rainbow === true || props.homepageData.insignia_is_rainbow === 'true') {
          rainbow = true;
        } else {
          rainbow = false;
        }
        setIsInitialised(true);
        init(color, rainbow);
      }
    }
  }, [props.homepageDataIsReceived, props.homepageData, init, isInitialised]);

  return (
    <button id="menuButton">
      <canvas id="menuButtonCanvas"></canvas>
    </button>
  )
}

export default MenuButton;