import { Link } from 'react-router-dom';
import MenuButton from './MenuButton.js';
import { AnimatePresence, motion } from 'framer-motion';

const Menu = (props) => {

  const { menuIsOpen, setMenuIsOpen } = props;
  const { homepageData, hideTermsOfCareMenuLink } = props;

  const openMenu = () => {
    setMenuIsOpen(true);
  }
   const closeMenu = () => {
    setMenuIsOpen(false);
  }

  const toggleMenu = () => {
    if (menuIsOpen === false) {
      setMenuIsOpen(true);
    } else {
      setMenuIsOpen(false);
    }
  }

  return (
    <div
      className={`menu__wrapper`}
      onMouseEnter={
        openMenu
      }
      onMouseLeave={
        closeMenu
      }
    >
      <div className="menu__wrapper__inner">
        <MenuButton
          onClick={toggleMenu}
          homepageData={homepageData}
          homepageDataIsReceived={props.homepageDataIsReceived}
        />
        <AnimatePresence initial={false}>
          {
            menuIsOpen === true ?
              <motion.nav id="menu"
                initial="initial"
                animate="enter"
                exit="exit"
              >
              <motion.a
                key={`menuItem1`} id={`menuItem1`} i={0}
                initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.4,
                  delay: 0,
                  ease: "easeInOut"
                }}
                className="menu__link"
                href="https://instagram.com/care.world"
                target="_blank"
              >@care.world</motion.a>
              <motion.a
                key={`menuItem2`} id={`menuItem2`} i={1}
                initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0 }}
                transition={{
                  duration: 0.4,
                  delay: 0.1,
                  ease: "easeInOut"
                }}
                className="menu__link"
                href="mailto:studio@care.world"
              >contact</motion.a>
              {
                hideTermsOfCareMenuLink === false &&
                <motion.span
                  key={`menuItem3`} id={`menuItem3`} i={1}
                  initial={{ opacity: 0, y: 40 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                  transition={{
                    duration: 0.4,
                    delay: 0.2,
                    ease: "easeInOut"
                  }}
                  className="menu__link"
                >
                  <Link to="/terms-of-care" className="menu__link__inner">
                    Terms of Care
                </Link>
                </motion.span>
              }
            </motion.nav>
          :
            ''
        }
        </AnimatePresence>
      </div>
    </div>
  )
}

export default Menu;