import { useState, useEffect } from "react";
import parse from 'html-react-parser';
import { motion } from "framer-motion";

const LandingPageParagraph = (props) => {

  const { children } = props;
  const [paragraph, setParagraph] = useState(children);

  useEffect(() => {
    setParagraph(children);
  }, [children]);

  return (
    <div
      className={`landing__paragraph${props.hiddenOnStart === true && props.active === true ? ' landing__paragraph--active' : ''}`}
    >
      {paragraph ? parse(paragraph.replace(/CARE/g, '<span class="logo-font">CARE</span>')) : ''}
    </div>
  )
}

const LandingPage = (props) => {

  const { homepageData } = props;
  const [initialised, setInitialised] = useState(false);
  const [activeParagraph, setActiveParagraph] = useState(-1);
  const [paragraphs, setParagraphs] = useState([]);

  useEffect(() => {
    const homepageParagraphs = [];

    if (homepageData.text) {

      for (let paragraph of homepageData.text) {
        homepageParagraphs.push(paragraph);
      }
      setParagraphs(homepageParagraphs);

      setTimeout(() => {
        setInitialised(true);

        let currentActiveParagraph = activeParagraph;

        let activeParagraphInterval = setInterval(() => {
          const landingParagraphs = document.querySelectorAll('.landing__paragraph');
          if (landingParagraphs[currentActiveParagraph + 1]) {
            currentActiveParagraph++;
            setActiveParagraph(currentActiveParagraph);
          } else {
            clearInterval(activeParagraphInterval);
          }
        }, 400);
      }, 200);
    }
  }, [homepageData.text, activeParagraph]);


  return (
    <motion.div
      className={`section section--landing`}
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: "easeInOut", duration: 0.6 }}
    >
      <div className={`font--heading landing__paragraphs__wrapper${initialised === true ? ' landing__paragraphs__wrapper--initialised' : ''}`}>
        <div className="landing__paragraphs__inner">
          {
            paragraphs[0] ?
              paragraphs.map(
                (paragraph, i) => (
                <LandingPageParagraph
                  key={i}
                  {...props}
                  active={activeParagraph >= i ? true : false}
                  hiddenOnStart={true}
                  index={i}
                >{paragraph.value}
                </LandingPageParagraph>
              )
            )
            :
            ''
          }
        </div>
      </div>
    </motion.div>
  )
}

export default LandingPage;