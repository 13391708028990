import { useState, useEffect, useCallback } from 'react';
import {
  BrowserRouter,
  Route,
  Switch
} from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import '../css/tachyons.min.css';
import '../css/index.css';

import Header from './components/Header.js';
import LandingPage from './views/LandingPage.js';
import TermsOfCare from './views/TermsOfCare.js';
import { cockpitUrl } from './cockpit.js';
import Menu from './components/Menu.js';

const App = (props) => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('rgb(255,255,255)');
  const [foregroundColor, setForegroundColor] = useState('rgb(0,0,0)');
  const [homepageData, setHomepageData] = useState({});
  const [termsData, setTermsData] = useState([]);
  const [termsPasswordMessage, setTermsPasswordMessage] = useState('');
  const [termsBackground, setTermsBackground] = useState('rgb(255,255,255)');
  const [termsForeground, setTermsForeground] = useState('rgb(0,0,0)');
  const [homepageBackground, setHomepageBackground] = useState('rgb(255,255,255)');
  const [homepageForeground, setHomepageForeground] = useState('rgb(0,0,0)');
  const [homepageDataIsReceived, setHomepageDataIsReceived] = useState(false);
  const [termsDataIsReceived, setTermsDataIsReceived] = useState(false);
  const [hideTermsOfCareMenuLink, setHideTermsOfCareMenuLink] = useState(true);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const getColours = useCallback(() => {
    const { pathname } = props.location;

    let newBackgroundColor = '#ffffff';
    let newForegroundColor = '#000000';

    if (pathname === '/') {
      newBackgroundColor = homepageBackground;
      newForegroundColor = homepageForeground;
    } else if (pathname === '/terms-of-care') {
      newBackgroundColor = termsBackground;
      newForegroundColor = termsForeground;
    }
    setBackgroundColor(newBackgroundColor);
    setForegroundColor(newForegroundColor);

    document.querySelector('html').style.backgroundColor = newBackgroundColor;
    document.querySelector('html').style.color = newForegroundColor;
  }, [props.location, homepageForeground, homepageBackground, termsForeground, termsBackground])

  const getFontData = () => {
    const apiKey = process.env.REACT_APP_API_KEY;

    fetch(`${cockpitUrl}/api/singletons/get/fonts?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        setHomepageData(response);
        setHomepageDataIsReceived(true);
        if (response.font_link && response.css_rules) {
          document.head.innerHTML += response.font_link;
          document.head.innerHTML += `<style>body, p {${response.css_rules}}</style>`;
        }
        if (response.font_size) {
          document.head.innerHTML += `<style>html, body{font-size:${response.font_size}pt;}</style>`;
        }
      })
      .catch(error => console.log(error));
  }

  const getSiteData = useCallback(() => {
    const apiKey = process.env.REACT_APP_API_KEY;

    fetch(`${cockpitUrl}/api/singletons/get/homepage?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        setHomepageData(response);
        setHomepageDataIsReceived(true);
        if (response.background_color) {
          setHomepageBackground(response.background_color);
        }
        if (response.foreground_color) {
          setHomepageForeground(response.foreground_color);
        }
        getColours();
      })
      .catch(error => console.log(error));

      fetch(`${cockpitUrl}/api/singletons/get/terms_of_care?token=${apiKey}`)
        .then(response => response.json())
        .then(response => {
          if (response.terms_of_care) {
            setTermsData(response.terms_of_care);
            setTermsDataIsReceived(true);
          }
          if (response.password_message) {
            setTermsPasswordMessage(response.password_message);
          }
          if (response.background_color) {
            setTermsBackground(response.background_color);
          }
          if (response.foreground_color) {
            setTermsForeground(response.foreground_color);
          }
          if (response.hide_menu_link) {
            setHideTermsOfCareMenuLink(response.hide_menu_link);
          } else {
            setHideTermsOfCareMenuLink(false);
          }
          getColours();
          setTimeout(() => {
            document.body.classList.add('loaded');
          }, 400);

        })
        .catch(error => console.log(error));
  }, [getColours])

  useEffect(() => {
    getSiteData();
    getColours();
    getFontData();
  }, [props.location.pathname, getSiteData, getColours]);

  const { location } = props;

  return (
    <div className={`app__inner${menuIsOpen === true ? ' menu-is-expanded' : ''}`}>
      {
        <Header backgroundColor={backgroundColor} foregroundColor={foregroundColor}/>
      }
      <Menu homepageData={homepageData} homepageDataIsReceived={homepageDataIsReceived} hideTermsOfCareMenuLink={hideTermsOfCareMenuLink} menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
      <AnimatePresence exitBeforeEnter initial={false}>
        <Switch location={location} key={location.pathname}>
          <Route exact path="/terms-of-care" render={() => (
            <TermsOfCare
              termsData={termsData}
              foregroundColor={foregroundColor}
              backgroundColor={backgroundColor}
              termsDataIsReceived={termsDataIsReceived}
              isSignedIn={isSignedIn}
              setIsSignedIn={setIsSignedIn} termsPasswordMessage={termsPasswordMessage}
            />
          )} />
          <Route exact path="/" render={() => (
            <LandingPage homepageData={homepageData} foregroundColor={foregroundColor} backgroundColor={backgroundColor} homepageDataIsReceived={homepageDataIsReceived} />
          )} />
        </Switch>
      </AnimatePresence>
    </div>
  )
}

const AppWrapper = () => {

  const onMouseWheel = (e) => {
    window.rotation -= e.deltaY * 0.01;
    if (window.rotation >= Math.PI * 2) {
      window.rotation = window.rotation % (Math.PI * 2)
    }
    if (window.rotation <= Math.PI * -2) {
      window.rotation = window.rotation % (Math.PI * -2)
    }
  }

  return (
    <div
      className="App app--main"
      onWheel={(e) => onMouseWheel(e)}>
      <BrowserRouter>
        <Route render={({ location }) => (
          <App location={location} />
        )} />
      </BrowserRouter>
    </div>
  )
};

export default AppWrapper;
