const cockpitUrl = "https://cms.careworld.info";
const assetsPath = "https://cms.careworld.info/";

const fetchSingletonData = (singletonName) => {
  const apiKey = process.env.REACT_APP_API_KEY;
  fetch(`${cockpitUrl}/api/singletons/get/${singletonName}?token=${apiKey}`)
  .then(data => data.json())
  .then(data => {
    return data
  })
  .catch(error => {
    console.log(error);
  })
}

const fetchCollectionData = (collectionName) => {
  const apiKey = process.env.REACT_APP_API_KEY;
  fetch(`${cockpitUrl}/api/collections/get/${collectionName}?token=${apiKey}`)
  .then(data => data.json())
  .then(data => {
    return data
  })
  .catch(error => {
    console.log(error);
  })
}

export { fetchSingletonData, fetchCollectionData, cockpitUrl, assetsPath };