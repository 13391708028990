import { useState, useEffect, useCallback } from "react";
import { cockpitUrl, assetsPath } from '../cockpit.js';
import Malarquee from 'react-malarquee';

import LogoMarque from '../components/LogoMarque.js';
import { AnimatePresence, motion } from 'framer-motion';

import parse from 'html-react-parser';

const TermsOfCareImage = ({src}) => (
  <div className="terms__image">
    <img src={assetsPath + src} className="terms__image__img" alt="" />
  </div>
);

const TermsOfCareOneColumnBodyText = (props) => (
  <div className="terms__text">
    { props.value ? parse(props.value.replace(/CARE/g, '<span class="logo-font">CARE</span>')) : '' }
  </div>
);

const ContactForm = (props) => {
  const [userEmail, setUserEmail] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const updateUserEmail = (e) => {
    const target = e.target;
    setUserEmail(target.value);
  }

  const updateUserMessage = (e) => {
    const target = e.target;
    setUserMessage(target.value);
  }

  const submitForm = (e) => {
    e.preventDefault();
    const apiKey = process.env.REACT_APP_API_KEY;
    fetch(`${cockpitUrl}/api/forms/submit/contact?token=${apiKey}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        form: {
          email: userEmail,
          message: userMessage
        }
      })
    })
    .then(entry => entry.json())

    setUserEmail('');
    setUserMessage('');
    setIsSubmitted(true);
  }

    return (
      <div className="terms__form">
        {
          isSubmitted === false ?
          <form
            className="form--contact"
            onSubmit={submitForm}
          >
            <p className="contact-form__intro">
              If you think your mission and values are aligned with ours or aspire to be – please get in touch for information about our portfolio and services.
            </p>
            <input
              onChange={updateUserEmail}
              className="contact-form__email"
              name="email"
              type="email"
              value={userEmail}
              placeholder="Email address"
            ></input>
            <textarea
              onChange={updateUserMessage}
              className="contact-form__message"
              name="message"
              value={userMessage}
              placeholder="Message (optional)"
            ></textarea>
            <input
              className="contact-form__submit"
              type="submit"
              value="Send"
            />
            </form>
            :
            <p>Thank you</p>
        }
      </div>
    )
}

const TermsOfCareThreeColumnBodyText = (props) => {

  const [columns, setColumns] = useState([]);

  const calculateColumns = useCallback(() => {
    const columnsArray = [];
    const { value } = props;

    let firstThirdLastIndex = Math.round(value.length * 0.33);
    let secondThirdLastIndex = Math.round(value.length * 0.66);

    if (value[firstThirdLastIndex] === '.' || value[firstThirdLastIndex] === '?' || value[firstThirdLastIndex] === '!') {
      const firstColumn = value.substring(0, firstThirdLastIndex + 1);
      columnsArray.push(firstColumn);
    } else {
      let everythingAfterFirstColumn = value.substring(firstThirdLastIndex + 1);


      const firstSpaces = [
        everythingAfterFirstColumn.indexOf('.'),
        everythingAfterFirstColumn.indexOf('?'),
        everythingAfterFirstColumn.indexOf('!')
      ];

      let firstSpace = -1;

      for (let firstSpaceIndex of firstSpaces) {
        if (firstSpaceIndex > -1) {
          if (firstSpace > -1) {
            if (firstSpaceIndex < firstSpace) {
              firstSpace = firstSpaceIndex;
            }
          } else {
            firstSpace = firstSpaceIndex;
          }
        }
      }

      firstSpace = firstSpace + 1;

      firstThirdLastIndex = firstThirdLastIndex + firstSpace;

      const firstColumn = value.substring(0, firstThirdLastIndex + 1);
      columnsArray.push(firstColumn);
    }

    if (value[secondThirdLastIndex] === '.' || value[secondThirdLastIndex] === '?' || value[secondThirdLastIndex] === '!') {
      const secondColumn = value.substring(firstThirdLastIndex + 1, secondThirdLastIndex + 1);
      columnsArray.push(secondColumn);
    } else {
      let everythingAfterSecondColumn = value.substring(secondThirdLastIndex + 1);

      const firstSpaces = [
        everythingAfterSecondColumn.indexOf('.'),
        everythingAfterSecondColumn.indexOf('?'),
        everythingAfterSecondColumn.indexOf('!')
      ];

      let firstSpace = -1;

      for (let firstSpaceIndex of firstSpaces) {
        if (firstSpaceIndex > -1) {
          if (firstSpace > -1) {
            if (firstSpaceIndex < firstSpace) {
              firstSpace = firstSpaceIndex;
            }
          } else {
            firstSpace = firstSpaceIndex;
          }
        }
      }

      firstSpace = firstSpace + 1;

      secondThirdLastIndex = secondThirdLastIndex + firstSpace;

      const secondColumn = value.substring(firstThirdLastIndex + 1, secondThirdLastIndex + 1).replace(/&nbsp;/g, '');
      columnsArray.push(secondColumn);
    }
    const thirdColumn = value.substring(secondThirdLastIndex + 1).replace(/&nbsp;/g, '');
    columnsArray.push(thirdColumn);

    setColumns(columnsArray);
  }, [props]);

  useEffect(() => {
    calculateColumns();
  }, [calculateColumns]);

  return (
    <div className="terms__text terms__text--three-column">
      {
        columns.map(
          (column, i) => <div className="terms__item__column" key={i}>{ column ? parse(column.replace(/CARE/g, '<span class="logo-font">CARE</span>')) : '' }</div>
        )
      }
    </div>
  )
}

const TermsOfCareTwoColumnBodyText = (props) => {
  const [columns, setColumns] = useState([]);

  const calculateColumns = useCallback(() => {
    const columnsArray = [];
    const { value } = props;

    let firstHalfLastIndex = Math.round(value.length * 0.5);

    if (value[firstHalfLastIndex] === '.' || value[firstHalfLastIndex] === '?' || value[firstHalfLastIndex] === '!') {
      const firstColumn = value.substring(0 + 1, firstHalfLastIndex + 1);
      columnsArray.push(firstColumn);
    } else {
      let everythingAfterFirstColumn = value.substring(firstHalfLastIndex + 1);

      const firstSpaces = [
        everythingAfterFirstColumn.indexOf('.'),
        everythingAfterFirstColumn.indexOf('?'),
        everythingAfterFirstColumn.indexOf('!')
      ];

      let firstSpace = -1;

      for (let firstSpaceIndex of firstSpaces) {
        if (firstSpaceIndex > -1) {
          if (firstSpace > -1) {
            if (firstSpaceIndex < firstSpace) {
              firstSpace = firstSpaceIndex;
            }
          } else {
            firstSpace = firstSpaceIndex;
          }
        }
      }

      firstSpace = firstSpace + 1;

      firstHalfLastIndex = firstHalfLastIndex + firstSpace;

      const firstColumn = value.substring(0, firstHalfLastIndex + 1);
      columnsArray.push(firstColumn);
    }
    const secondColumn = value.substring(firstHalfLastIndex + 1);
    columnsArray.push(secondColumn);

    setColumns(columnsArray);
  }, [props]);

  useEffect(() => {
    calculateColumns();
  }, [props.value, calculateColumns]);

  return (
    <div className="terms__text terms__text--two-column">
      {
        columns[0] ?
          columns.map(
            (column, i) => <div className="terms__item__column" key={i}>{ column ? parse(column.replace(/CARE/g, '<span class="logo-font">CARE</span>')) : ''}</div>
          ) : ''
      }
    </div>
  )
}

const TermsOfCareOneColumnLargeText = (props) => (
  <div className="terms__text terms__text--large">
    { props.value ? parse(props.value.replace(/CARE/g, '<span class="logo-font">CARE</span>')) : '' }
  </div>
)

const TermsOfCareHeading = (props) => (
  <div className="terms__heading">
    { props.value ? parse(props.value.replace(/CARE/g, '<span class="logo-font">CARE</span>')) : '' }
  </div>
)

const TermsOfCareScroller = (props) => {
  return (
    <div className="terms__item--marquee">
      {
        props.value ?
        <Malarquee
          className="terms__marquee"
          rate={20}
        >
          <span className="terms__marquee__span uc">{props.value ? parse(props.value.replace(/CARE/g, '<span class="logo-font">CARE</span>')) : ''}</span>
          <span className="terms__marquee__span terms__marquee__span--img">
            <LogoMarque {...props} />
          </span>
        </Malarquee> : ''
      }
    </div>
  )
}

const TermsOfCareQuote = (props) => (
  <p className="terms__quote">
    { (props.value.replace(/CARE/g, '<span class="logo-font">CARE</span>')) }
  </p>
)

const TermsOfCareListDashes = (props) => (
  <ul className="terms__list terms__list--unordered">
    { props.items.map(
      (item, i) => (
        <li key={i}>{item.value ? parse(item.value.replace(/CARE/g, '<span class="logo-font">CARE</span>')) : ''}</li>
      )
    ) }
  </ul>
)

const TermsOfCareListNumbers = (props) => (
  <ol className="terms__list terms__list--ordered">
    { props.items.map(
      (item, i) => (
        <li key={i}>{item.value ? parse(item.value.replace(/CARE/g, '<span class="logo-font">CARE</span>')) : ''}</li>
      )
    ) }
  </ol>
)

const termsOfCareItemRenderSwitch = (props) => {
  switch (props.field.name) {
    case 'image':
      return (<TermsOfCareImage src={props.value.path} />);
    case 'text_1_column':
      return (<TermsOfCareOneColumnBodyText {...props} />);
    case 'text_1_column_large':
      return (<TermsOfCareOneColumnLargeText {...props} />);
    case 'text_2_column':
      return (<TermsOfCareTwoColumnBodyText {...props} />);
    case 'text_3_column':
      return (<TermsOfCareThreeColumnBodyText {...props} />);
    case 'heading':
      return (<TermsOfCareHeading {...props} />);
    case 'quote':
     return (<TermsOfCareQuote {...props} />);
    case 'scroller':
      return (<TermsOfCareScroller {...props} />);
    case 'list_dashes':
      return (<TermsOfCareListDashes
        items={props.value} />);
    case 'list_numbers':
      return (<TermsOfCareListNumbers
        items={props.value} />);
    case 'contact_form':
      return (<ContactForm />);
    default:
      return
  }
}

const TermsOfCareItem = (props) => (
  <div className={`terms__item terms__item--${props.field.name}${ props.active === true ? ' terms__item--active' : ''}`}>
    {
      termsOfCareItemRenderSwitch(props)
    }
  </div>
);

const PasswordEntryForm = (props) => {

  const { setIsSignedIn, passwordHasBeenAttempted, setPasswordHasBeenAttempted, termsPasswordMessage } = props;
  const [inputText, setInputText] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const apiKey = process.env.REACT_APP_API_KEY;

    fetch(`${cockpitUrl}/api/singletons/get/terms_of_care?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        if (response.password) {
          if (response.password === inputText) {
            setIsSignedIn(true);
          } else {
            setPasswordHasBeenAttempted(true);
          }
        } else {
          setPasswordHasBeenAttempted(true);
        }
      })
      .catch(error => console.log(error));
  }

  return (
    <form className="terms__password" onSubmit={handleSubmit}>{
        termsPasswordMessage && termsPasswordMessage !== '' &&
        <label htmlFor="password" className="terms__password__label">
          {termsPasswordMessage ? parse(termsPasswordMessage.replace(/CARE/g, '<span class="logo-font">CARE</span>')) : ''}
        </label>
      }
      <input type="password" name="password" placeholder="Password" value={inputText} onChange={(e) => { setInputText(e.target.value) }} className="terms__password__input" />
      <input type="submit" name="Password submit" className="terms__password__submit" />
      {
        passwordHasBeenAttempted &&
        <p>Incorrect password. Please try again.</p>
      }
    </form>
  )
}

const TermsOfCare = (props) => {

  const { isSignedIn, setIsSignedIn, termsData, termsPasswordMessage } = props;

  const [activeItem, setActiveItem] = useState(-1);
  const [isInitialised, setIsInitialised] = useState(false);
  const [passwordHasBeenAttempted, setPasswordHasBeenAttempted] = useState(false);

  useEffect(() => {
    if (termsData) {
      if (termsData[0]) {
        if (isInitialised === false && isSignedIn === true) {
          setIsInitialised(true);
          let currentActiveItem = activeItem;
          setTimeout(() => {
            let activeItemInterval = setInterval(() => {
              const termsItems = document.querySelectorAll('.terms__item');
              if (termsItems[currentActiveItem + 1]) {
                currentActiveItem++;
                setActiveItem(currentActiveItem);
              } else {
                clearInterval(activeItemInterval);
              }
            }, 90);
          }, 900);
        }
      }
    }
  }, [termsData, activeItem, isInitialised, isSignedIn]);

    return (
      <motion.div
        className={`section section--terms__wrapper`}
        initial={{ opacity: 1 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: "easeOut", duration: 0.6 }}
      >
        <AnimatePresence>
          {
            isSignedIn === true ?
              <motion.div className={`section--terms`}
                initial={{ y: 1200 }}
                animate={{ y: 0 }}
                exit={{ y: 1200 }}
                transition={{ ease: "easeOut", duration: 0.6 }}
              >
                {
                  termsData ?
                    termsData[0] ?
                      termsData.map(
                        (item, i) => <TermsOfCareItem
                          {...item}
                          {...props}
                          key={i}
                          index={i}
                          active={activeItem >= i ? true : false}
                        />
                      )
                      : ''
                    : ''
                }
              </motion.div>
              :
              <PasswordEntryForm
                passwordHasBeenAttempted={passwordHasBeenAttempted}
                setPasswordHasBeenAttempted={setPasswordHasBeenAttempted}
                setIsSignedIn={setIsSignedIn}
                termsData={termsData}
                termsPasswordMessage={termsPasswordMessage}
              />
          }
        </AnimatePresence>
      </motion.div>
    )

}

export default TermsOfCare;