import Logo from './Logo.js';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const pickBackgroundImage = (backgroundColor) => {
  if (window.innerWidth < 768 && backgroundColor) {

    const backgroundColorMinusStart = backgroundColor.replace('rgb(', '');
    const backgroundColorMinusStartAndEnd = backgroundColorMinusStart.replace(')', '');

    const backgroundColorArray = backgroundColorMinusStartAndEnd.split(',');

    const bg = {
      r: backgroundColorArray[0],
      g: backgroundColorArray[1],
      b: backgroundColorArray[2]
    }

    return `linear-gradient(${backgroundColor} 40px, rgba(${bg.r},${bg.g},${bg.b},0))`;
  } else {
    return 'none';
  }
}

const Header = (props) => (
  <motion.header
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
    className="header"
    style={{
      backgroundImage: pickBackgroundImage(props.backgroundColor)
    }}
  >
    <Link to="/">
      <Logo foregroundColor={props.foregroundColor} />
    </Link>
  </motion.header>
);

export default Header;