const Logo = (props) => (
  <svg className="logo" width="129.379" height="30.716" viewBox="0 0 129.379 30.716" fill={props.foregroundColor}>
    <g transform="translate(-439.65 -295.446)">
      <g transform="translate(472.905 295.446)">
        <g transform="translate(66.51 0)">
          <path d="M612.533,319.309c-6.149-2.04-7.255-6.691-4.33-8.232a13.307,13.307,0,0,1,3.886-.7,14.121,14.121,0,0,0,8.525-4.716,5.636,5.636,0,0,1,6.651-2.056,7.455,7.455,0,0,1,2.788,1.8.371.371,0,0,0,.615-.394,15.358,15.358,0,1,0-.491,12.653.37.37,0,0,0-.567-.453C625.87,320.277,618.658,321.34,612.533,319.309Zm-1.411-20.053c3.838-1.809,8.155-.922,9.254,1.409s-1.122,5.687-4.959,7.5-7.84,1.386-8.938-.945S607.285,301.064,611.123,299.255Z" transform="translate(-601.086 -295.446)" />
        </g>
        <path d="M509.509,295.461a15.3,15.3,0,0,0-10.687,3.69.238.238,0,0,0,.269.388q.668-.361,1.385-.677c3.356-1.481,10.081-3.511,15.6,1.783,2.248,2.156,3.266,4.9,2.862,7.074s-2.338,2.9-5.18,2.186a23.47,23.47,0,0,0-12.193.414c-3.859,1.219-6.392.756-7.683-.432a.237.237,0,0,0-.4.161q-.02.374-.021.755a15.358,15.358,0,1,0,16.047-15.343Zm-1.052,24.251c-4.79,0-8.707-2.215-8.707-4.682s3.883-4.466,8.674-4.466,8.674,2,8.674,4.466S513.247,319.712,508.457,319.712Z" transform="translate(-493.462 -295.446)" />
        <g transform="translate(33.376 0)">
          <path d="M570.325,310.682c-4.076-1.31-2.566-3.611-.967-4.555a7.162,7.162,0,0,1,6.547-.493.756.756,0,0,0,.986-1,15.357,15.357,0,1,0-15.975,21.4.179.179,0,0,0,.092-.342,13.147,13.147,0,0,1-6.585-6.429c-1.641-4.41-.444-8.468,3.809-8.888,6.2-.614,13.153,1.9,13.745,7.84.257,2.573-1.34,5.045-3.674,6.552a.178.178,0,0,0,.158.317,15.4,15.4,0,0,0,8.229-7.68,2.889,2.889,0,0,0,.153-2.161C575.9,312.457,572.864,311.5,570.325,310.682Zm-7.971-2.92c-4.139,1.081-8.115-.414-8.879-3.339s1.972-6.174,6.112-7.254,8.115.413,8.879,3.339S566.494,306.681,562.354,307.762Z" transform="translate(-547.47 -295.446)" />
        </g>
      </g>
      <g transform="translate(439.65 295.831)">
        <path d="M466.294,317.975c-4.1,3.057-13.85,3.67-18.784-.363a9.678,9.678,0,0,1-3.325-10.258c1.143-3.134,3.568-3.769,7.437-1.834,3.1,1.549,6.2,1.261,10.281-.061a7.228,7.228,0,0,1,7.361,1.272.247.247,0,0,0,.408-.252,15.4,15.4,0,0,0-7.178-8.848.246.246,0,0,0-.308.372,3.93,3.93,0,0,1,.93,2.488c0,2.755-3.178,4.988-7.1,4.988s-7.1-2.233-7.1-4.988a4.7,4.7,0,0,1,2.812-3.955.247.247,0,0,0-.171-.459,15.358,15.358,0,1,0,18.269,18.989.247.247,0,0,0-.44-.206A13.284,13.284,0,0,1,466.294,317.975Z" transform="translate(-439.65 -296.069)" />
      </g>
    </g>
  </svg>
);

export default Logo;
