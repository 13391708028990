const LogoMarque = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" className="logomarque" width="42.048" height="42.048" viewBox="0 0 42.048 42.048" fill={props.foregroundColor}>
    <g id="Group_30" data-name="Group 30" transform="translate(-754.874 -295.002)">
      <g id="Group_23" data-name="Group 23" transform="translate(759.895 300.586)">
        <g id="Group_22" data-name="Group 22">
          <path id="Path_2" data-name="Path 2" d="M785.607,321.172c-4.254,3.169-14.357,3.8-19.473-.376a10.031,10.031,0,0,1-3.448-10.635c1.185-3.249,3.7-3.908,7.71-1.9,3.213,1.6,6.426,1.308,10.659-.063a7.5,7.5,0,0,1,7.632,1.317.255.255,0,0,0,.421-.26,15.962,15.962,0,0,0-7.44-9.174.255.255,0,0,0-.32.386,4.085,4.085,0,0,1,.964,2.58c0,2.857-3.295,5.172-7.359,5.172s-7.358-2.315-7.358-5.172a4.869,4.869,0,0,1,2.915-4.1.256.256,0,0,0-.178-.476,15.922,15.922,0,1,0,18.942,19.686.256.256,0,0,0-.457-.213A13.8,13.8,0,0,1,785.607,321.172Z" transform="translate(-757.984 -298.461)"/>
        </g>
      </g>
      <g id="Group_25" data-name="Group 25" transform="translate(754.874 295.002)">
        <g id="Group_24" data-name="Group 24">
          <path id="Path_3" data-name="Path 3" d="M775.9,337.05a21.024,21.024,0,1,1,21.024-21.024A21.047,21.047,0,0,1,775.9,337.05Zm0-40.553a19.529,19.529,0,1,0,19.529,19.529A19.551,19.551,0,0,0,775.9,296.5Z" transform="translate(-754.874 -295.002)"/>
        </g>
      </g>
    </g>
  </svg>
)

export default LogoMarque;